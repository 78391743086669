<template>
  <p class="p-zensavvy">{{translations.first_step.title}}</p>
  
  <div class="d-flex flex-row-reverse flex-wrap align-items-start" style="gap: 15px 150px;">
    
    <div class="d-flex flex-column" style="flex: 3;">
      <div class="options-container justify-content-around my-3">
        
        <div class="option" v-for="(option, index) in services"
          :key="index" :class="{ selected: submission.services.includes(option.slug) }" @click="toggleOption(option.slug)">
          <span class="option-text">{{ option.name }}</span>
          <span v-if="submission.services.includes(index)" class="p-zensavvy">
              <i classs="fa-solid fa-check"></i>
          </span>
        </div>
      </div>
      <label class="zensavvy-error w-100 text-left mt-3" style="font-size: 11px !important;" v-for="error in errors.services.$errors">
        {{ error.$message }}  
      </label>
    </div>
  </div>
  

</template>

<script>
export default {
    inject: ['axios', 'locale', 'translations'],

    props: {
      modelValue: { type: Object, required: true },
      errors: Object
    },

    data: () => ({
      services: [],
    }),

    computed: {
      submission: {
        get() {
          return this.modelValue
        },

        set(value) {
          this.$emit('update:modelValue', value)
        }
      }
    },

    methods: {
        getServices() {
            this.axios.get(`/services?locale=${this.locale}`).then(r => {
                this.services = r.data.data
            })
        },

        toggleOption(slug) {
            if (this.submission.services.includes(slug)) {
                this.submission.services = this.submission.services.filter(i => i !== slug);
            } else {
                this.submission.services.push(slug);
            }
        },

        getLabel(index) {
            let labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
            return labels[index]
        }
    },

    mounted() {
      this.getServices()
    },
}
</script>

<style lang="scss" scoped>


.submit-btn {
  width: 100%;
  padding: 15px;
  border-radius: 30px;
  background-color: #a44de5;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border: none;
  text-align: center;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-btn:hover {
  background-color: #9639d6;
}
</style>