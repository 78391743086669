<template>
    <p class="p-zensavvy">{{translations.fourth_step.message}}</p>
    <textarea class="form-control text-center" v-model="submission.message" style="min-height: 150px;"> </textarea>
</template>

<script>
export default {
    inject: ['translations'],

    props: {
        modelValue: { type: Object, required: true }
    },

    computed: {
        submission: {
            get() {
                return this.modelValue
            },

            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>