<template>
    <p class="p-zensavvy">{{ translations.third_step.title }}</p>

    <div class="w-100 mb-4">
        <input type="email" class="form-control text-center" :placeholder="translations.third_step.company" style="width:100%;" v-model="submission.company"/>
        <label class="zensavvy-error text-left" style="font-size: 11px !important;" v-for="error in errors.company.$errors">
            {{ error.$message }}  
        </label>
    </div>
</template>

<script>
export default {
    inject: ['translations'],
    props: {
        modelValue: { type: Object, required: true },
        errors: Object
    },

    computed: {
        submission: {
            get() {
                return this.modelValue
            },

            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>