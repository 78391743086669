<template>
    <p class="p-zensavvy">{{ translations.second_step.title }}</p>
    <div class="d-flex align-items-start justify-content-start flex-wrap w-100" style="flex: 1;min-width: 200px;">
      <div class="w-100 mb-4">
        <input type="text" class="form-control text-center" :placeholder="translations.second_step.first_name" v-model="submission.first_name"/>
        <label class="zensavvy-error text-left" style="font-size: 11px !important;" v-for="error in errors.first_name.$errors">
          {{ error.$message }}  
        </label>
      </div>

      <div class="w-100 mb-4">
        <input type="text" class="form-control text-center" :placeholder="translations.second_step.last_name" style="width:100%;" v-model="submission.last_name"/>
        <label class="zensavvy-error text-left" style="font-size: 11px !important;" v-for="error in errors.last_name.$errors">
          {{ error.$message }}  
        </label>
      </div>

      <div class="w-100 mb-4">
        <input type="email" class="form-control text-center" :placeholder="translations.second_step.email" style="width:100%;" v-model="submission.email"/>
        <label class="zensavvy-error text-left" style="font-size: 11px !important;" v-for="error in errors.email.$errors">
          {{ error.$message }}  
        </label>
      </div>

    </div>
</template>

<script>
export default {
    inject: ['translations'],

    props: {
        modelValue: { type: Object, required: true },
        errors: Object
    },

    computed: {
        submission: {
            get() {
                return this.modelValue
            },

            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>